"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findStyle = void 0;
const isRegisteredStyle = (style) => {
    if (typeof style === "object" && style != null)
        return "__registeredStyleBrand" in style;
    else
        return false;
};
const findStyle = (style, stylePropertyKey) => {
    if (Array.isArray(style)) {
        for (let i = style.length - 1; i >= 0; i--) {
            const result = exports.findStyle(style[i], stylePropertyKey);
            if (result != null)
                return result;
        }
        return undefined;
    }
    else {
        if (style == null) {
            return undefined;
        }
        else if (typeof style === "boolean") {
            return undefined;
        }
        else if (isRegisteredStyle(style)) {
            return style.__registeredStyleBrand[stylePropertyKey];
        }
        else if (typeof style === "object") {
            return style[stylePropertyKey];
        }
        else {
            return undefined;
        }
    }
};
exports.findStyle = findStyle;
